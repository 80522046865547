$(document).on("turbolinks:load", function(){
    $(".counter").each(function () {
        let location = $(this);
        let max = $(this).text();
        let start = 0;
        let number = setInterval(function () {
            start++;
            if (start > max) {
                start = max;
                clearInterval(number);
            }
            location.text(start);
        }, 50);
    });
});