// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'styles/application'

import 'jquery';
import 'js-cookie';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'swiper';
import "jquery-lazyload/jquery.lazyload"

import '../js/counter';
import '../js/game_card';
import '../js/swiper_banner';
import '../js/footer';
import '../js/event';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)