$(document).on("turbolinks:load", function(){
    $("img.lazy").lazyload({
        threshold : 200,
        effect : "fadeIn"
    });

    $('#popup').click(function (n) {
        if ($(n.target).parents("#popup").length == 0) {
            $('#popup,#popup > div').removeClass('active')
        }
    })
    $('.game-card').click(function () {
        var imgSrc = $(this).find('img').attr('src');
        var gameName = $(this).find('.gameInfo div').text();
        var detail = $(this).find('.detail');
        var gameType = detail.data('type');
        var introduce = detail.data('introduce');
        var layout = detail.data('layout');
        var line = detail.data('line');
        var volatility = detail.data('volatility');
        var rtp = detail.data('rtp');
        var demo = detail.data('demo');
        var themed = detail.data('themed');
        var name = detail.data('name');

        console.log(detail);
        
        $('#popup img').attr('src', imgSrc);
        $('#popup .game-type div').eq(-2).text(gameType);
        $('#popup .game-type div:last-child').text(name);
        $('#popup .layout div:last-child').text(layout);
        $('#popup .line div:last-child').text(line);
        $('#popup .volatility div:last-child').text(volatility);
        $('#popup .rtp div:last-child').text(rtp);
        $('#popup .introduce').text(introduce);

        $('#popup .themed').hide();
        $('#popup .cancel').hide();
        if(gameType == 'Slot') {
            $('#popup .themed').show();
        } else {
            $('#popup .cancel').show();
        }
        $('#popup .normal').data('href', demo);
        $('#popup .themed').data('href', themed);

        $('#popup,#popup > .gamepop').addClass('active');
    })
    $('.icon-close,.cancel').click(function () {
        $('#popup,#popup > div').removeClass('active');
    })
    $('#popup .submit').click(function(){
        window.location.href = $(this).data('href');
    });

    $('.filter button').click(function () {
        console.log($(this));
        console.log($(this).siblings());
        console.log($(this).attr('data'));
        console.log($(this).data('type'));
        type = $(this).data('type');
        $('.filter button').removeClass('active');
        $(this).addClass('active');
        $('.all .game-card').hide();
        if (type === 'all') {
            $('.all .game-card').show();
        } else {
            $('.all .' + type.toLowerCase() + '-game').show();
        }
        $("img.lazy").lazyload();
    })

    $("#search_input").keyup(function(){
        var searchText = $(this).val().toLowerCase();
        $(".all .game-card").each(function(){
            var gameName = $(this).find(".gameInfo div").text().toLowerCase();
            if(gameName.indexOf(searchText) !== -1){
                $(this).show();
            }else{
                $(this).hide();
            }
        });
        $("img.lazy").lazyload();
    });
});