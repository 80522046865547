let type = Cookies.get('mode') === undefined ? 'light' : Cookies.get('mode');
sitemode(type);
function sitemode(type) {
    if (type === "dark") {
        $('#darkmode').addClass('active')
        $('html').addClass('darkMode')
        $('.mask').addClass('active')
    }
}
$(document).on("turbolinks:load", function(){
    $("#goTop").click(function () {
        $("html,body").animate(
            { scrollTop: 0, }, 0
        );
        return false;
    });

    $('#darkmode').click(function () {
        $('#darkmode').toggleClass('active')
        $('html').toggleClass('darkMode')
        $('.mask').toggleClass('active').css('transition','0.6s')
        $('.logo-d').toggle();
        $('.logo-w').toggle();
        if (type === "light") {
            type = "dark";
        } else {
            type = "light";
        }
        Cookies.set("mode", type);
    })
});